import NewSectionTitle from '@components/shared/NewSectionTitle';
import { Tab } from '@headlessui/react';
import React, { Fragment } from 'react';

import woolfMiniLogo from '@assets/woolf-mini-logo.svg';

import iitTransparentLogo from '@assets/new-iitg-short-logo.png';
import woolfNewLogo from '@assets/woolf-new.png';

import IITBg from '@assets/partners-bg/new-IIT-Guwahati-bg.webp';
import WoolfBg from '@assets/partners-bg/woolf-bg.webp';
import Image from 'next/image';

import cn from 'classnames';
import { Primary, Secondary } from 'stories/Button.stories';
import { sectionScroll } from '@components/shared/scetionScroll';

function ElitePartners() {
  const TAB_LOGO_MAP = {
    'E&ICT, IIT Guwahati': {
      logo: null,
    },

    Woolf: {
      logo: woolfMiniLogo,
      width: 50,
      height: 40,
      objectFit: 'contain',
    },
  };

  const PARTNERS_MAP = {
    'E&ICT, IIT Guwahati': {
      title: 'AlmaBetter X E&ICT Academy, IIT Guwahati',
      description: `E&ICT Academy, IIT Guwahati, a leading Indian Institute of Technology, excels in education, research, and innovation. Our partnership with EICT Academy at IIT Guwahati aims to advance global education standards. Leveraging the Institute’s esteemed reputation, we offer world-class educational opportunities and foster a global academic community.`,
      background: IITBg,
      secondaryCTA: 'Visit Academy',
      CTALink: 'https://eict.iitg.ac.in/index_partners_details?p=clpuUnliREhCM0prVjErRU1YZmJyUT09',
      imageObject: {
        logo: iitTransparentLogo,
        overlayWidth: 210,
        overlayHeight: 55,
        cardWidth: 165,
        cardHeight: 40,
      },
    },

    Woolf: {
      title: 'AlmaBetter X Woolf',
      description:
        'Woolf is the world’s first global collegiate higher education institution. It is licensed and globally accredited as a higher education institution in the European Union that works on ECTS - European Credit Transfer System. Degrees & Certifications awarded by Woolf are globally recognised with recognition from ECE and CES.',
      background: WoolfBg,
      secondaryCTA: 'Visit Woolf',
      CTALink: 'https://woolf.university/academics/college/09d88ade-66bd-4b84-8aab-b70232434c2b',
      imageObject: {
        logo: woolfNewLogo,
        overlayWidth: 200,
        overlayHeight: 40,
        cardWidth: 145,
        cardHeight: 34,
      },
    },
  };

  return (
    <div className="flex flex-col gap-y-[40px] py-[40px] px-[16px] md:px-[124px] md:pt-[72px] md:pb-[116px]">
      <div className="flex flex-col gap-y-[16px]">
        <NewSectionTitle text={`We've partnered with`} highlightedText={'Top Institutions'} />
        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-gray-400 md:text-[18px] md:leading-7">
          AlmaBetter Innovarsity is an innovative and forward-thinking educational institution that
          offers technology education. We are an autonomous college*, licensed as a higher education
          institution in the European Union. Our programs are designed to equip learners with the
          skills and knowledge they need to succeed in today's dynamic and rapidly changing job
          market.
        </p>
      </div>
      <div className="flex w-full flex-col gap-y-[54px]">
        <Tab.Group>
          <Tab.List className={'flex w-full items-center justify-center gap-x-[16px]'}>
            {Object.keys(PARTNERS_MAP).map((key) => {
              const { logo, width, height, objectFit } = TAB_LOGO_MAP[key];

              return (
                <Tab key={key} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={cn(
                        'relative z-10 border-b-4 px-[8px] py-[8px] focus:outline-none md:px-[16px]',
                        {
                          'border-red text-red': selected,
                          'border-transparent text-white': !selected,
                        }
                      )}
                      style={{ fontWeight: 600 }}
                    >
                      <span className="flex items-center gap-x-[8px]">
                        {logo && (
                          <Image src={logo} width={width} height={height} objectFit={objectFit} />
                        )}

                        <p
                          className={cn('font-satoshi-bold text-[20px] capitalize md:text-[28px]', {
                            'text-black': !selected,
                            'text-red': selected,
                          })}
                        >
                          {key}
                        </p>
                      </span>
                    </button>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels className={'w-full'}>
            {Object.values(PARTNERS_MAP).map((partnerData) => (
              <Tab.Panel
                key={partnerData.title}
                className={'relative z-10 flex flex-col items-center gap-y-[32px]'}
              >
                <PartnersCard {...partnerData} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default ElitePartners;

const PartnersCard = ({ title, description, background, imageObject, secondaryCTA, CTALink }) => {
  const { logo, overlayWidth, overlayHeight, cardWidth, cardHeight } = imageObject;

  return (
    <div className="grid gap-x-[24px] overflow-hidden rounded-2xl bg-[#1A1A1A] md:grid-cols-2">
      <div className="relative grid">
        <Image src={background} width={540} height={450} objectFit="cover" />
        <div className="absolute bottom-4 left-6 z-10">
          <Image src={logo} objectFit="cover" width={overlayWidth} height={overlayHeight} />
        </div>

        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
      </div>

      <div className="flex flex-col gap-y-[32px] py-[40px] px-[16px] md:pl-0 md:pr-[24px]">
        <div className="flex items-center justify-center gap-x-[22px] md:justify-start md:gap-x-[32px]">
          <Image src={'/assets/almabetter.png'} objectFit="contain" width={135} height={24} />
          <span className="text-white">X</span>

          <Image src={logo} objectFit="cover" width={cardWidth} height={cardHeight} />
        </div>

        <div className="flex flex-col gap-y-[16px] pb-[8px]">
          <h3 className="text-center font-satoshi-bold text-[28px] leading-8 text-white md:text-left">
            {title}
          </h3>
          <p className="text-center font-satoshi-medium text-[16px] leading-6 text-gray-2 md:text-left">
            {description}
          </p>
        </div>

        <div className="flex items-center justify-center gap-x-[20px] md:justify-start">
          <Primary
            label={'Explore Courses'}
            size={'large'}
            onClick={(e) => sectionScroll({ e, ref: '#featuredCourses', topValue: 50 })}
          />
          <Secondary linkCTA label={secondaryCTA} size={'large'} href={CTALink} target={'_blank'} />
        </div>
      </div>
    </div>
  );
};
