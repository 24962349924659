import React from 'react';

import NewSectionTitle from '@components/shared/NewSectionTitle';
import dynamic from 'next/dynamic';
import { HierarchyPrimary, Primary } from 'stories/Button.stories';
import SliderComponent from '@components/shared/CustomSlider';

const ArticleCard = dynamic(() => import('@components/shared/bytes/article-card/ArticleCard'), {
  ssr: false,
});

const TutorialCard = dynamic(
  () => import('@components/shared/bytes/bytes-tutorials/TutorialCard'),
  {
    ssr: false,
  }
);

function BytesClub({ articlesData, tutorialsData }) {
  return (
    <div className="flex flex-col gap-y-[120px] bg-[#FCFCFD] px-[16px] pt-[56px] pb-[62px] md:py-[80px] md:px-[124px]">
      <div className="flex flex-col items-center gap-y-[8px] md:gap-y-[16px]">
        <NewSectionTitle
          text={'AlmaBetter'}
          highlightedText={'Bytes'}
          underlineStyle={'underline-offset-[12px]'}
        />

        <p className="text-center font-satoshi-regular text-[16px] leading-6 text-gray-400 md:text-[20px] md:leading-8">
          Love the world of tech? Access free training resources including blogs, articles,
          tutorials and start your upskilling journey!
        </p>
      </div>

      {/* Article */}
      {articlesData && (
        <PostSection
          title={'Top Articles'}
          dataList={articlesData}
          link={'/bytes/articles'}
          Component={ArticleCard}
        />
      )}

      {/* Tutorial */}
      {tutorialsData && (
        <PostSection
          title={'Top Tutorials'}
          dataList={tutorialsData}
          link={'/bytes/tutorials'}
          Component={TutorialCard}
        />
      )}
    </div>
  );
}

export default BytesClub;

function PostSection({ title, dataList = [], link, Component }) {
  return (
    <div className="flex flex-col items-center gap-y-16 md:items-start">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col gap-y-[8px] md:gap-y-[20px]">
          <p className="font-satoshi-bold text-[20px] leading-8 md:text-[30px] md:leading-9">
            {title}
          </p>
          <p className="font-satoshi-medium text-[16px] leading-7 text-gray-400 md:font-satoshi-regular md:text-[20px] md:leading-8">
            Tool and strategies modern teams need to help their companies grow.
          </p>
        </div>
        <div className="hidden md:block">
          <Primary linkCTA label={'View all posts'} href={link} target={'_blank'} />
        </div>

        <div className="w-36 self-center md:hidden">
          <HierarchyPrimary linkCTA label={'View all posts'} href={link} target={'_blank'} />
        </div>
      </div>
      <div className="hidden gap-8 md:grid md:grid-cols-3">
        {dataList?.slice(0, 3).map(({ id, attributes }) => {
          return <Component key={id} {...attributes} />;
        })}
      </div>
      <div className="w-full overflow-hidden md:hidden">
        <SliderComponent
          swiperOptions={{
            breakpoints: {
              320: {
                slidesPerView: 1,
                spaceBetween: 16,
              },
            },
          }}
          navigation={false}
          paginate
          paginationLayout="flex items-center gap-x-[12px] col-span-12 justify-center"
        >
          {dataList?.slice(0, 3).map(({ id, attributes }) => {
            return <Component key={id} {...attributes} />;
          })}
        </SliderComponent>
      </div>
    </div>
  );
}
