import UAParser from 'ua-parser-js';

export function getDeviceType() {
  const userAgent = typeof window !== 'undefined' ? navigator.userAgent : 'desktop';
  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || 'desktop';
  return deviceType;
}
