import React from 'react';

import SliderComponent from '@components/shared/CustomSlider';
import Image from 'next/image';
import { Primary, Secondary } from 'stories/Button.stories';
import { sectionScroll } from '@components/shared/scetionScroll';
import DarkLayout from '@components/shared/DarkLayout';

import iitTransparentLogo from '@assets/new-iitg-short-logo.png';
import woolfNewLogo from '@assets/woolf-new.png';

import one from '@assets/partners-bg/1.png';
import two from '@assets/partners-bg/2.png';
import three from '@assets/partners-bg/3.png';
import four from '@assets/partners-bg/4.png';
import five from '@assets/partners-bg/5.png';
import six from '@assets/partners-bg/6.png';
import seven from '@assets/partners-bg/7.png';
import eight from '@assets/partners-bg/8.png';
import nine from '@assets/partners-bg/9.png';
import ten from '@assets/partners-bg/10.png';

const slidersImageList = [one, two, three, four, five, six, seven, eight, nine, ten];

function MainBanner() {
  return (
    <DarkLayout customStyle="!flex flex-col gap-y-[32px] md:gap-y-[40px] py-[40px] md:py-[80px]">
      <div className="relative z-10 flex flex-col items-center gap-y-[16px] px-[16px] md:px-[200px]">
        <h1 className="text-center text-[36px] leading-tight text-white md:text-[56px]">
          India's #1 Platform in Tech <span className="text-red">Upskilling</span>
        </h1>
        <p className="text-center font-satoshi-regular text-[24px] leading-8 text-gray-2">
          Kickstart your high-growth tech career with global degrees and upskilling courses in Data
          Science & Software Development.
          <br /> In partnership with
        </p>
      </div>

      <div className="flex flex-col items-center gap-[22px] md:flex-row md:gap-[32px]">
        {/* <p className="mr-[4px] font-satoshi-bold text-[25px] text-white">E&ICT, IIT GUWAHATI</p> */}
        <Image src={iitTransparentLogo} objectFit="cover" width={160} height={55} />
        <span className="text-white">X</span>
        <Image src={woolfNewLogo} objectFit="cover" width={165} height={36} />
      </div>

      <div className="relative z-10 flex items-center gap-x-[16px] self-center">
        <Primary
          label={'Explore Courses'}
          size={'large'}
          onClick={(e) => sectionScroll({ e, ref: '#featuredCourses', topValue: 50 })}
        />

        <Secondary
          label={'Book a Demo Class'}
          size={'large'}
          onClick={(e) => sectionScroll({ e, ref: '#program-banner', topValue: 50 })}
        />
      </div>

      <div className="relative z-10 w-full overflow-hidden pt-[26px]">
        <SliderComponent
          swiperOptions={{
            effect: 'coverflow',
            breakpoints: {
              320: {
                slidesPerView: 2,
                spaceBetween: 5,
              },

              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            },
            roundLengths: true,
            loop: true,
            coverflowEffect: {
              rotate: 80,
              depth: -100,
              modifier: 0.2,
              scale: 1.1,
            },
            speed: 10000,
            autoplay: {
              delay: 0,
            },
          }}
          navigation={false}
          swiperSlideStyle="!flex justify-center"
        >
          {slidersImageList.map((image, index) => (
            <div key={index} className="relative flex w-[200px] flex-col gap-y-[18px] md:w-fit">
              <Image src={image} width={274} height={296} objectFit="contain" loading="lazy" />
            </div>
          ))}
        </SliderComponent>
      </div>
    </DarkLayout>
  );
}

export default MainBanner;
