import { useRouter } from 'next/router';
import useStorage from '../../utils/hooks/useStorage';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SliderComponent from './CustomSlider';
import Image from 'next/image';
import NewSectionTitle from './NewSectionTitle';

const PressHighlights = ({
  pressHighlights,
  showSeeMore,
  showFeatured,
  title,
  newLayout = false,
  newHeadingLayout = false,
}) => {
  const router = useRouter();
  const { getItem } = useStorage();
  const currentUtm = getItem('utm') && JSON.parse(getItem('utm'));
  const highlights = pressHighlights?.attributes?.highlights || [];
  // set query string for redirect
  let queryString = '';
  for (const property in currentUtm) {
    queryString += `&${property}=${currentUtm[property]}`;
  }
  const responsive = {
    mobile: {
      breakpoint: { max: 678, min: 0 },
      items: 1.5,
    },
  };

  const pressHighlightsData = showFeatured
    ? highlights?.filter((news) => news?.isFeatured)
    : highlights;

  if (!pressHighlightsData?.length) return null;

  return (
    <>
      {newLayout ? (
        <NewPressHighlights pressHighlightsData={pressHighlightsData} />
      ) : (
        <div className="flex flex-col items-center gap-y-[40px] bg-[#F9FAFB] py-32 md:gap-y-[60px]">
          {newHeadingLayout ? (
            <NewSectionTitle text={'AlmaBetter in'} highlightedText={'News'} />
          ) : (
            <h2 className="mb-10 font-satoshi-bold">{title || 'AlmaBetter in News'}</h2>
          )}
          <div className="hidden flex-row flex-wrap justify-center gap-4 md:flex">
            {pressHighlightsData
              ?.sort((a, b) => (a.rank ?? Infinity) - (b.rank ?? Infinity))
              .slice(0, 10)
              .map((item) => (
                <a
                  key={item.id}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="mb-5 flex w-56 cursor-pointer flex-col items-center rounded-md bg-white p-6 py-8 lg:mb-3"
                  style={{
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                  }}
                >
                  <div className="mb-4 h-12">
                    <img
                      src={item?.image?.data?.attributes?.url}
                      alt="news-brand"
                      className="h-full w-full object-contain"
                    />
                  </div>
                  <p className="text-body text-satoshi-medium text-center text-[16px] leading-6">
                    {item.description}
                  </p>
                </a>
              ))}
          </div>
          {/* mobile ui */}
          <div className="mx-auto flex w-screen items-center md:hidden md:w-full lg:mx-0 ">
            <Carousel
              swipeable={true}
              draggable={true}
              responsive={responsive}
              arrows={true}
              ssr={true} // means to render carousel on server-side.
              itemClass="slider-crousel"
              keyBoardControl={true}
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              deviceType="mobile"
              dotListClass="owl-dots"
              showDots={true}
              autoPlay={false}
              sliderClass="flex gap-x-4"
            >
              {pressHighlightsData
                ?.sort((a, b) => (a.rank ?? Infinity) - (b.rank ?? Infinity))
                ?.map((item) => (
                  <a
                    key={item.id}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="mb-5 flex h-60 w-56 cursor-pointer flex-col items-center gap-y-2 overflow-hidden rounded-md bg-white p-6 lg:mb-3"
                    style={{
                      boxShadow:
                        'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    }}
                  >
                    <div className="h-12">
                      <img
                        src={item?.image?.data?.attributes?.url}
                        alt="news-brand"
                        className="h-full w-full object-contain"
                      />
                    </div>
                    <p className="text-body text-center md:w-auto">{item.description}</p>
                  </a>
                ))}
            </Carousel>
          </div>
          {showSeeMore && (
            <button
              className="mt-12 w-fit rounded-sm bg-red px-12 py-2 text-white"
              onClick={() =>
                router.push(queryString ? `/press-highlights?${queryString}` : `/press-highlights`)
              }
            >
              See More
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default PressHighlights;

function NewPressHighlights({ pressHighlightsData }) {
  return (
    <div className="flex flex-col gap-y-[72px] md:px-[124px] md:py-[80px]">
      <NewSectionTitle text={'AlmaBetter in the'} highlightedText={'News'} />

      <div className="w-full overflow-hidden">
        <SliderComponent
          swiperOptions={{
            breakpoints: {
              320: {
                slidesPerView: 1.2,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 32,
              },
            },
          }}
          buttonStyle={{
            buttonClass: 'col-span-2 md:col-span-5 rounded-full p-[16px]',
            leftButton: 'place-self-end',
            rightButton: 'place-self-start',
          }}
          paginate
          paginationLayout="flex items-center gap-x-[12px] col-span-8 md:col-span-2 place-self-center justify-center"
        >
          {pressHighlightsData &&
            pressHighlightsData
              .sort((a, b) => (a.rank ?? Infinity) - (b.rank ?? Infinity))
              .map((item) => (
                <a
                  key={item.id}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="h-full"
                >
                  <div
                    className="h-full overflow-hidden rounded-lg bg-white"
                    style={{
                      boxShadow:
                        '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                    }}
                  >
                    <div className="border border-gray-2">
                      <Image
                        src={item?.image?.data?.attributes?.url}
                        alt="news-brand"
                        width={378}
                        height={267}
                        objectFit="contain"
                      />
                    </div>

                    <p className="p-[16px] font-satoshi-medium text-[18px] leading-7">
                      {item.description}
                    </p>
                  </div>
                </a>
              ))}
        </SliderComponent>
      </div>
    </div>
  );
}
