import Image from 'next/image';
import investers from './investors.json';
import DarkLayout from '@components/shared/DarkLayout';
import NewSectionTitle from '@components/shared/NewSectionTitle';
import SliderComponent from '@components/shared/CustomSlider';
import { HierarchyPrimary } from 'stories/Button.stories';
import RecognizedBy from '@components/reuseable/RecognizedBy';

const SeedFunding = () => {
  return (
    <DarkLayout
      gridMode
      customStyle="!flex flex-col gap-y-[97px] py-[40px] px-[16px] md:py-[80px] md:px-[124px]"
    >
      <div className="relative z-10 flex w-full flex-col items-center gap-y-[64px]">
        <div className="flex flex-col items-center gap-y-[16px]">
          <NewSectionTitle
            text={'Backed by Leading Tech'}
            headingStyle={'text-white'}
            highlightedText={'Entreprenuers'}
          />
          <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-gray-2 md:text-[18px] md:leading-7">
            AlmaBetter’s vision and journey are endorsed by leading investors - visionary
            entrepreneurs, and eminent leaders from the world of tech.
          </p>
        </div>
        <div className="w-full pb-[8px]">
          <SliderComponent
            swiperOptions={{
              breakpoints: {
                320: {
                  slidesPerView: 2,
                  spaceBetween: 16,
                  slidesPerGroup: 1,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 32,
                },
              },
              autoplay: {
                delay: 3000,
              },
            }}
            navigation={false}
            paginate
            paginationLayout={'flex w-full justify-center gap-x-[12px] col-span-12'}
          >
            {investers?.map(({ designation, company, imgFile, name }, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center gap-y-[24px] capitalize"
              >
                <Image
                  src={`/assets/images/seed-funding/${imgFile}`}
                  height={124}
                  width={124}
                  quality={90}
                  alt="almabetter"
                  objectFit="cover"
                />
                <div className="flex flex-col gap-y-[8px]">
                  <h3 className="font-satoshi-medium font-satoshi-medium text-[24px] font-medium  leading-8 text-white">
                    {name}
                  </h3>
                  <p className="pb-[8px] font-satoshi-medium text-[16px] leading-5 text-[#787878]">
                    {designation}&#x2C;
                  </p>
                  <p className="text-[18px] text-red">{company}</p>
                </div>
              </div>
            ))}
          </SliderComponent>
        </div>
        <div className="flex flex-col items-center gap-y-[4px] gap-x-[8px] md:flex-row">
          <Image
            src="/assets/images/seed-funding/kalaari.png"
            alt="almabetter"
            width={195}
            height={90}
            objectFit="cover"
          />
          <p className="mb-0.5 pt-[12px] font-satoshi-regular text-[20px] leading-7 text-gray-2 md:pt-0">
            and 10+ renowned angel investors
          </p>
          <HierarchyPrimary
            linkCTA
            label={'Learn More'}
            size={'xl'}
            href={
              'https://www.almabetter.com/blogs/alma-better-secures-funding-of-2-7-million-led-by-kalaari-capital-and-other-angel-investors'
            }
            target={'_blank'}
          />
        </div>
      </div>
      <div className="w-full">
        <RecognizedBy showBanner />
      </div>
    </DarkLayout>
  );
};

export default SeedFunding;
